import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { Button as MuiButton } from '@material-ui/core'
import useButtonStyles from './useButtonStyles'

const ButtonProps = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'outline', 'outline-secondary', 'outline-black']),
}

const Button = (props) => {
  const classes = useButtonStyles(props)
  const muiProps = omit(props, 'size', 'color', 'variant', 'disabled')

  if (props.variant === 'secondary') {
    muiProps.variant = 'contained'
    muiProps.color = 'secondary'
  } else if (props.variant === 'tertiary') {
    muiProps.variant = 'text'
    muiProps.color = 'secondary'
  } else {
    muiProps.variant = 'contained'
    muiProps.color = 'primary'
  }

  return (
    <MuiButton
      disableElevation
      disableRipple
      disableTouchRipple
      classes={classes}
      {...muiProps} />
  )
}

Button.propTypes = ButtonProps

export { ButtonProps }

export default Button
