import { makeStyles, createStyles } from '@material-ui/core/styles';

export const getSize = (
  theme,
  props,
) => {
  const letterSpacing = theme.direction === 'ltr' ? '0.1px' : 0;

  if (props.size === 'small') {
    return {
      fontSize: '.875rem',
      padding: props.variant === 'tertiary' ? '6px 6px' : '6px 14px',
      letterSpacing,
      lineHeight: '1.25rem',
    };
  }
  // default size is medium
  return {
    fontSize: '1rem',
    padding: props.variant === 'tertiary' ? '10px 14px' : '12px 32px',
    letterSpacing,
    lineHeight: '1.5rem',
  };
};

export const variants = {
  primary: (theme) => {
    const {
      white, blue, darkBlue, purple,
    } = theme.palette.core

    return {
      color: white,
      backgroundColor: blue,
      border: `2px solid ${blue}`,

      '&:hover': {
        borderColor: darkBlue,
        backgroundColor: darkBlue,
      },

      '&:focus': {
        borderColor: darkBlue,
        boxShadow: `${white} 0px 0px 0px 1px, ${blue} 0px 0px 0px 2px`,
      },

      '&:active': {
        borderColor: purple,
        backgroundColor: purple,
      },
    }
  },

  secondary: (theme) => {
    const { palette } = theme
    const {
      white, blue, darkBlue, purple,
    } = palette.core

    return {
      color: blue,
      backgroundColor: white,
      border: `2px solid ${blue}`,

      '&:hover': {
        color: darkBlue,
        borderColor: darkBlue,
        backgroundColor: palette.blue[50],
      },

      '&:focus': {
        border: `2px solid ${darkBlue}`,
        boxShadow: `${white} 0px 0px 0px 1px, ${palette.purple[600]} 0px 0px 0px 2px`,
      },

      '&:active': {
        color: purple,
        borderColor: purple,
      },
    }
  },

  tertiary: (theme) => {
    const { palette } = theme
    const {
      blue, darkBlue, purple,
    } = palette.core

    return {
      margin: 0,
      padding: 0,
      minWidth: 0,
      textDecoration: 'none',
      color: blue,
      border: `2px solid transparent`,

      '&:hover': {
        textDecoration: 'underline',
        color: darkBlue,
        backgroundColor: palette.blue[50],
      },

      '&:focus': {
        color: darkBlue,
        backgroundColor: palette.blue[50],
        borderColor: palette.purple[600],
      },

      '&:active': {
        textDecoration: 'underline',
        color: purple,
        backgroundColor: palette.purple[50],
      },

      '&:visited': {
        textDecoration: 'underline',
        color: purple,
        backgroundColor: palette.purple[50],
      },
    }
  },
  outline: (theme) => {
    const {
      white,
    } = theme.palette.core

    return {
      color: white,
      backgroundColor: 'transparent',
      border: `2px solid ${white}`,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    }
  },
  'outline-secondary': (theme) => {
    const {
      blue,
    } = theme.palette.core

    return {
      color: blue,
      backgroundColor: 'transparent',
      border: `2px solid ${blue}`,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    }
  },
  'outline-black': (theme) => {
    const {
      black,
    } = theme.palette.core

    return {
      color: black,
      backgroundColor: 'transparent',
      border: `2px solid ${black}`,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    }
  },
};

export default makeStyles((theme) => createStyles({
  root: (props) => ({
    borderRadius: 4,
    textTransform: 'none',
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,

    ...variants[props.variant](theme),
    ...getSize(theme, props),
  }),
  label: () => ({ textTransform: 'none' }),
}));
