import React from 'react';
import PropTypes from 'prop-types';
import {
  simpleTextMain,
  simpleTextContainer,
  simpleTextBodyCopy,
  simpleTextBodyCopyAlignedRight,
  headingLeftAligned,
  contentRightAligned,
  contentLeftAligned,
  headingRightAligned,
  flexSpaceBetween,
  flexRowReverse,
} from './ContentfulSimpleText.module.css';
import { convertRichTextToComponent } from '../utils';

const ContentfulSimpleText = ({
  simpleTextHeading,
  headingLevel,
  variant,
  backgroundColor,
  bodyCopy,
}) => {
  const isHeadingAlignedLeft = variant === 'Left';
  const backgroundColors = {
    White: 'bg-white text-dark',
    Blue: 'bg-blue text-light',
    Gray: 'bg-gray text-dark',
    Black: 'bg-black text-light',
  };

  const Heading = ({ level, children }) => {
    switch (level) {
      case 'H2':
        return <h2>{children}</h2>;
      case 'H3':
        return <h3>{children}</h3>;
      default:
        return <h1>{children}</h1>;
    }
  };

  Heading.propTypes = {
    level: PropTypes.string,
    children: PropTypes.node,
  };
  return (
    <section
      className={`${simpleTextMain} ${backgroundColors[backgroundColor]}`}>
      <div
        className={`container ${
          isHeadingAlignedLeft ? flexSpaceBetween : flexRowReverse
        }`}>
        <div
          className={`${simpleTextContainer} ${
            isHeadingAlignedLeft ? headingLeftAligned : headingRightAligned
          }`}>
          <Heading level={headingLevel}>{simpleTextHeading}</Heading>
        </div>
        <div
          className={`${simpleTextContainer} ${
            isHeadingAlignedLeft ? contentRightAligned : contentLeftAligned
          }`}>
          <span
            className={`${simpleTextBodyCopy} ${
              !isHeadingAlignedLeft && simpleTextBodyCopyAlignedRight
            }`}>
            {convertRichTextToComponent(bodyCopy)}
          </span>
        </div>
      </div>
    </section>
  );
};

ContentfulSimpleText.propTypes = {
  simpleTextHeading: PropTypes.string,
  headingLevel: PropTypes.string,
  variant: PropTypes.string,
  backgroundColor: PropTypes.string,
  bodyCopy: PropTypes.object,
};

export default ContentfulSimpleText;
