import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import MuiTypography from '@material-ui/core/Typography'
import useTypographyStyles from './useTypographyStyles';

const Typography = (props) => {
  const classes = useTypographyStyles(props)
  let { variant } = props
  const updatedProps = omit(props, ['variant'])

  if (variant === 'd1' || variant === 'd2') {
    variant = 'h1'
  }

  return (
    <MuiTypography
      classes={classes}
      variant={variant}
      {...updatedProps} />
  )
}

Typography.propTypes = {
  component: PropTypes.string,
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  fontWeight: PropTypes.oneOf([400, 600, 700]),
  variant: PropTypes.oneOf([
    'd1',
    'd2',
    'h1',
    'h2',
    'h3',
    'h4',
    'body1',
    'body2',
    'inherit',
  ]),
  color: PropTypes.oneOf([
    'inherit',
    'body',
    'primaryHeadline',
    'highlight',
    'supportText',
    'error',
    'success',
    'eyebrowYellow',
    'eyebrowAqua',
    'eyebrowOrange',
  ]),
}

Typography.defaultProps = {
  color: 'inherit',
  variant: 'body1',
}

export default Typography
