import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core'
import SectionWrapper from 'components/SectionWrapper'
import Typography from 'components/theme/Typography'
import CBackground from 'static/images/backgrounds/c-shape.png'
import styles from './Manifesto.module.scss'

const Manifesto = ({
  className, backgroundColor, title, content,
}) => {
  const isTablet = useMediaQuery({ maxWidth: styles.tablet })
  const backgroundStyle = (!isTablet) ? {
    backgroundImage: `url(${CBackground})`,
  } : {}

  return (
    <SectionWrapper
      className={className}
      backgroundColor={backgroundColor}
      backgroundImage={(isTablet) ? CBackground : null}>
      <Grid
        direction="column"
        container
        item>
        <div className={cx('py-2xl', styles.sectionBackground)} style={backgroundStyle}>
          <Typography className={cx('mb-lg')} variant="d2" align="center">
            {title}
          </Typography>
          <Typography variant="h1" fontWeight={400} align="center">
            {content}
          </Typography>
        </div>
      </Grid>
    </SectionWrapper>
  )
}

Manifesto.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
}

export default Manifesto
