import { makeStyles, createStyles } from '@material-ui/core/styles';

export const getColor = (
  theme,
  typographyColor,
) => {
  const { palette } = theme
  const {
    black, purple, blue, darkBlue,
  } = palette.core

  const themeColors = {
    inherit: 'inherit',
    black,
    purple,
    blue,
    darkBlue,
  }

  return themeColors[typographyColor];
};

export default makeStyles((theme) => (
  createStyles({
    root: (props) => ({
      color: getColor(theme, props.color || 'inherit'),
      ...theme.typography[props.variant],
      fontWeight: props.fontWeight || '',
    }),
  })
))
