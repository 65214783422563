import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({
  title,
  description,
  author,
  meta,
  slug,
  lang,
}) => {
  const siteUrl = process.env.SITE_URL
  const canonicalContent = slug ? `${siteUrl}${slug}` : siteUrl
  const metaRobots = meta.find((item) => item.property === 'robots');
  const robotsContent = (metaRobots && metaRobots.content) || 'noindex';
  const image = `${siteUrl}images/logos/coursera-logo-full-rgb.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${author}`}
      meta={[
        {
          name: `robots`,
          content: `${robotsContent}`,
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:site_name`,
          content: `${title} | ${author}`,
        },
        {
          property: `og:title`,
          content: `${title} | ${author}`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:url`,
          content: canonicalContent,
        },
        // {
        //   property: `og:locale`,
        //   content: site.siteMetadata.locale,
        // },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: description,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${author}`,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image:src`,
          content: image,
        },
        {
          name: `twitter:site`,
          content: `${title} | ${author}`,
        },
        {
          name: `twitter:app:name:iphone`,
          content: `${title} | ${author}`,
        },
        {
          name: `twitter:app:name:ipad`,
          content: `${title} | ${author}`,
        },
        {
          name: `twitter:app:name:googleplay`,
          content: `${title} | ${author}`,
        },
        {
          name: `twitter:app:id:iphone`,
          content: `id736535961`,
        },
        {
          name: `twitter:app:id:ipad`,
          content: `id736535961`,
        },
        {
          name: `twitter:app:id:googleplay`,
          content: `org.coursera.android`,
        },
        {
          property: `fb:admins`,
          content: `727836538,4807654`,
        },
        {
          property: `fb:app_id`,
          content: `823425307723964`,
        },
      ].concat(meta)}
      link={[{ rel: 'canonical', href: canonicalContent }]} />
  );
}

SEO.defaultProps = {
  author: 'Coursera',
  description: ``,
  meta: [],
  lang: `en`,
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  slug: PropTypes.string,
  lang: PropTypes.string,
};

export default SEO;
